<template>
  <ftx-dialog
    :dialog="dialog"
    position="standard"
    :maximized="isMobile ? false : false"
    :persistent="true"
    className="q-disclaimer"
  >
    <template v-slot:DialogContent>
      <div class="disclaimer-block">
        <div class="disclaimer-img">
          <img
            v-if="theme?.logoURL"
            :style="
              !$q.screen.lt.md
                ? 'max-width:100%; width:auto; max-height:100px;'
                : ' max-width:100%; width:auto; max-height:50px;'
            "
            :src="theme.logoURL"
            class="logo"
          />
          <label
            v-else
            class="q-my-none text-h6 text-weight-bold bg-white q-py-sm q-px-md r-8 s-1"
            v-text="businessConfigurations.businessName || ''"
          />
        </div>
        <div class="disclaimer-content">
          <q-card class="announcement-card">
            <q-card-section class="card-header items-center">
              <q-avatar
                size="60px"
                font-size="1.75rem"
                color="primary"
                text-color="white"
                icon="assignment"
              />
              <!-- <q-chip color="primary">
                <q-icon size="2rem" color="white" name="assignment" />
              </q-chip> -->
              <div class="heading q-pl-md">
                <div class="text-h4">Disclaimer</div>
                <p class="q-mb-none"
                  >Before navigating to our website to explore our online
                  products and place an order, please read the disclaimer below
                  about our website.</p
                >
              </div>
            </q-card-section>

            <q-card-section class="custom-scroll hidden-x bulletListScroll">
              <ul class="bulletList">
                <li
                  v-for="(disclaimer, index) in disclaimers"
                  :key="`${disclaimer.id}-${index}`"
                >
                  <div>
                    <div class="text-subtitle2 text-weight-bold">{{
                      disclaimer.title
                    }}</div>
                    <div
                      class="text-subtitle2"
                      v-html="disclaimer.description"
                    />
                  </div>
                </li>
              </ul>
              <!-- <div
                  class="q-my-sm text-dark"
                  v-for="(disclaimer, index) in disclaimers"
                  :key="index"
                >
                  <div class="text-subtitle2 text-weight-bold">{{
                    disclaimer.title
                  }}</div>
                  <div class="text-subtitle2" v-html="disclaimer.description" />
                </div> -->

              <!-- <EmptyComponent v-else image="images/empty-disclaimer.png">
                <p>There is no any disclaimer.</p>
              </EmptyComponent> -->
            </q-card-section>
            <q-card-actions align="center" class="form-action">
              <q-btn
                unelevated
                no-caps
                label="I understand. Proceed to Store"
                color="primary"
                :size="isMobile ? '14px' : '16px'"
                :padding="isMobile ? '8px 16px' : '12px 36px'"
                v-close-popup
                @click="markAsRead"
              />
            </q-card-actions>
          </q-card>
        </div>
      </div>
    </template>
  </ftx-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DisclaimerDialog',
  data: function () {
    return {
      dialog: 'DisclaimerDialog',
    }
  },
  components: {},
  computed: {
    ...mapGetters('theme', ['theme']),
    ...mapGetters('persistedLocal', ['disclaimerUpdate']),
    dialogState: {
      get() {
        return this.getDialogState(this.dialog)
      },
      set(val) {
        this.changeDialogState({
          dialog: this.dialog,
          val,
        })
      },
    },
    properties() {
      return this.getDialogProperties(this.dialog)
    },
    disclaimers() {
      let disclaimers = this.commonStateByKey('disclaimers') || []

      if (!Object.keys(this.disclaimerUpdate).length) return disclaimers
      else
        return disclaimers.filter((disclaimer) => {
          return (
            !this.disclaimerUpdate?.hasOwnProperty(disclaimer.id) ||
            (this.disclaimerUpdate?.hasOwnProperty(disclaimer.id) &&
              this.disclaimerUpdate[disclaimer.id] != disclaimer.updatedAt)
          )
        })
    },
  },
  methods: {
    markAsRead() {
      this.addDisclaimer()
      this.dialogState = false
    },
    addDisclaimer() {
      var obj = {}
      for (var i = 0; i < this.disclaimers.length; i++) {
        obj[this.disclaimers[i].id] = this.disclaimers[i].updatedAt
      }

      this.$store.commit('persistedLocal/SET_DISCLAIMER_UPDATE', obj)
    },
  },
}
</script>

<style lang="scss">
/* Dialog CSS :: Start */
.q-disclaimer {
  z-index: 60002 !important;
  .q-dialog__inner--minimized {
    padding: 0;
    > div.disclaimer-block {
      width: 100%;
      max-width: 100%;
      max-height: 100% !important;
      height: 100dvh;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      background: #fff;
      border-radius: 0;
      -webkit-border-radius: 0;
      .announcement-card {
        .card-header {
          border-bottom: 0;
          margin-bottom: 0;
        }
        @media (max-width: 1023px) {
          .q-card__section {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
      .q-card {
        box-shadow: none;
      }
      .disclaimer-img {
        width: 645px;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        label {
          position: absolute;
          z-index: 2;
        }
        @media (max-width: 1440px) {
          width: 45%;
        }
        @media (max-width: 1200px) {
          width: 40%;
        }
        @media (max-width: 1023px) {
          width: 100%;
          height: 120px;
        }
        &:before,
        &:after {
          content: '';
          background: $secondaryOnBody;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 0;
        }
        &:after {
          background: url(/images/disclaimeravbg.jpg) center center no-repeat;
          background-size: cover;
          opacity: 0.7;
        }
        .logo {
          width: 100px;
          position: relative;
          z-index: 1;
        }
      }
      .disclaimer-content {
        width: calc(100% - 675px);
        padding: 0 100px;
        @media (max-width: 1440px) {
          padding: 0 70px;
          width: 55%;
        }
        @media (max-width: 1200px) {
          padding: 0 50px;
          width: 60%;
        }
        @media (max-width: 1023px) {
          width: 100%;
          padding: 0 50px;
        }
        @media (max-width: 991px) {
          padding: 0 30px;
        }
        @media (max-width: 768px) {
          padding: 0 15px;
        }
        .announcement-card.q-card {
          min-width: 100%;
          max-width: 100%;
          .notes {
            @media (max-width: 1024px) {
              margin: 0;
            }
            li {
              padding-bottom: 20px;
            }
          }
          .card-header {
            justify-content: flex-start;
            @media (max-width: 1023px) {
              margin-bottom: 0;
              margin-top: 15px;
            }
            .heading {
              display: block;
              .text-h4 {
                margin: 0;
              }
            }
            /* .q-chip {
              width: 76px;
              height: 76px;
              border-radius: 50%;
              display: flex !important;
              justify-content: center;
              align-items: center;
              flex-wrap: wrap;
              margin: 0 20px 0 0;
              font-size: 45px;
              padding: 10px 15px;
              @media (max-width: 767px) {
                width: 60px;
                height: 60px;
                margin: 0 15px 0 0;
                font-size: 32px;
              }
            } */
          }
        }
      }
      .custom-scroll {
        padding-top: 0;
        padding-bottom: 0;
        max-height: calc(100dvh - 350px);
      }
      @media (min-width: 1024px) {
        justify-content: space-between;
        .custom-scroll {
          max-height: calc(100dvh - 220px);
        }
      }
      @media (min-width: 1200px) {
      }
      @media (max-width: 1023px) {
        flex-direction: column;
        flex-wrap: nowrap;
        .disclaimer-img {
          flex: 0 0 auto;
        }
        .disclaimer-content {
          flex: 1 0 0;
        }
        .custom-scroll {
          height: calc(100dvh - 330px);
        }
      }
      @media (max-width: 767px) {
        .custom-scroll {
          max-height: calc(100dvh - 350px);
        }
      }
    }
  }
}
/* Dialog CSS :: End */
</style>
